/* eslint-disable */
export const receptionDeskVars = {
  setup: {
    kioskID: 2101,
    locationID: 777000,
    // apiUrl: "https://canonrsna.com/2023/api",
    // apiUrl: "https://canonrsna.com/2023/api_SANDBOX",
    apiUrl: "https://canonrsna.com/2023/api_LIVEACTIVITY",
    qrCodeURL: "https://canonrsna.com/VirtualReception/followup",
    intervals: {
      useParticipantMessages: 10000, //15000//5000
      sendTextPauseBeforeCanTextAgain: 850,
      dir: "directions",
    },
    cacheTimeOut: 10 * 60 * 1000,
    // cacheTimeOut: 5 * 60 * 1000,
    defaultSort: { key: 'last_name', direction: 'ascending' },
    messagebubblePrfx: "msg",
    version: "1.5.0",
  },


  headerLinks: [
    { name: "RECEPTION DESK HOME", link: "/home/search/reset", unread: true },
    // { name: "INBOX", link: "logout", unread: true },
    // { name: "LIVE", link: "logout" },
    { name: "ADD PERSON", link: "/home/search/addperson", addperson: true },
    { name: "SIGN OUT", link: "/logout" },
  ],



  mainTabs: [
    { name: "Search", link: "search",  searchresults: true},
    { name: "Message", link: "messages", unread: true },
  ],




  searchResultsColumns: {
    byPerson: [
      // { key: "id", "label": "#" },
      { key: "last_name", "label": "Last Name", flag: "primary" },
      { key: "first_name", "label": "First Name", flag: "primary" },
      { key: "title", "label": "Title" },
      { key: "department_txt", "label": "Department" },
      { key: "title2", "label": "Misc." },
      { key: "email", "label": "Email" },
      { key: "cellphone", "label": "Cell Phone" },
    ],
  },


  text: {
    error: {
      noParticipantName: "Please select a participant...",
      addingParticipant: "Add Participant...",
      part: "participant",
      dir: "directions",
    },
    misc: {
      searchPlaceHolder: "Search by first or last name...",
      textMessagePlaceHolder: "► Send to -> ",
      searchResultsTerm: "                      ",
      searchResultsTermNoResults: "No results",
      messagesNoResults: "- Messages Empty -",
      selectedPerson: "Selected Participant",//Current RSNA Attendee
      latestMessage: "Recent Messages",
      unreadMessages: "Unread Messages",
      groupPrefixText: "►",// Group: ►

      receptionDeskTitle: "Canon Medical",
      receptionDeskSubtitle: "RSNA 2023 Reception Desk",
      receptionDeskThirdline: "Please sign in below",
      fname: "First",
      lname: "Last",
      code: "Access Code",
      editPersonHeaderPrefix: "EDIT: ",
      addPersonHeaderPrefix: "Add new participant:  ",
    },

  },


  faicons: {
    partInfoIcon: "fa-solid fa-circle-info",
    partInfoErrorIcon: "fa-solid fa-triangle-exclamation",
    clipBoardIcon: "fa-solid fa-clipboard",
    incomingMessageNavIcon: "fa-regular fa-comments",
    outgoingMessageNavIcon: "fa-regular fa-comment",
    resetIcon3: "fa-solid fa-arrow-left",//fa-arrow-rotate-left
    resetIcon2: "fa-regular fa-power-off fa-xl",
    resetIcon: "fa-solid fa-arrow-rotate-left fa-xl",
    resetIconX: "fa-solid fa-circle-xmark fa-lg",//fa-solid fa-xmark fa-xl
    resetIconX2: "fa-regular fa-circle-xmark fa-xl",
    triangle: "fa-solid fa-angle-right fa-xl",
    scrollto: "fa-solid fa-angles-up",
    caret: "fa-solid fa-caret-right",
    searchRowClickIndicatorHeader: "fa-solid fa-comments",
    searchRowClickIndicator: "fa-solid fa-circle-arrow-right",
    editPersonHeaderIcon: "fa-solid fa-user-pen",
    editPersonHeaderIconClose: "fa-solid fa-circle-xmark",
    addPersonHeaderIcon: "fa-solid fa-user-plus",
    addPersonTopHeaderIcon: "fa-solid fa-user-plus fa-xs",
  },

  buttons: {
    shortcut: "Select",//►//Shortcut//Select By Country
    searchClear: "Clear",
    send: "Send",
    messagClear: "Clear",
    signIn: "Sign In",
  },

  initialPrefix: {
    currentCard: "Selected: ",
    incoming: "Sent by: ",
    outgoing: "Sent to: ",
    unkknowUserNamePrefix: "Select a participant...",
    unkknowUserName: "??",
    addUserName: "+",
  },

  imgs: {
    headerLogo: { file: "https://canonrsna.com/canon_logos/canon@0.5x.png", txt: "Canon Medical" },
    headerLogo2: { file: "https://iggentr.wpenginepowered.com/education/wp-content/uploads/sites/4/2022/06/canon-logo.jpg", txt: "Canon Medical" },
    rsnaLogo: { file: "https://www.rsna.org/-/media/Images/RSNA/Annual-meeting/2023/RSNA_2023_Branded_Logo_AM_Dates_FINAL.ashx?la=en&hash=3D468330F3D0737F0B04F67E38083E7F96D6B0AB", txt: "RSNA 2023" },
  },



  misc: {
    alphabet: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    min_count_to_show_letters: 10,
  },
};

export function getObjKey(objs, value) {
  let i = 0;
  for (const obj of objs) {
    const k = Object.keys(obj).find((key) => obj[key] === value);
    if (k) {
      return objs[i];
    }
    i++;
  }

  // return Object.keys(obj).find((key) => obj[key] === value);
}
